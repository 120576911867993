import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`A page with no HTTPS`}</h1>
    <p>{`This is my personl non-HTTPS page to allow me to sign-in onto public-wifis.`}</p>
    <p>{`This page was inspired by:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://mitm.cool"
        }}>{`http://mitm.cool`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://neverssl.com"
        }}>{`http://neverssl.com`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      